import React from "react"
import Thumbnail from "../../components/Thumbnail"
import GoBackArrow from "../../components/GoBackArrow"

export default function main() {
  return (
    <div className="sounds-page">
      <GoBackArrow />
      <h1>ROYALTY FREE SOUNDS</h1>
      <p>Use this sound into your video or composition:</p>
      <div className="thumbnails-row">
        <Thumbnail caption="PACK 1" />
        <Thumbnail caption="PACK 2" />
        <Thumbnail caption="PACK 3" />
        <Thumbnail caption="PACK 4" />
      </div>
      <br />
      <p> Still didn't find what you looking for? </p>
      <p>
        Drop us a line
        <br />
        <a
          href="mailto:tracks@smileysound.com?Subject=Hey Smiley Sound! I'm interested"
          target="_top"
        >
          tracks@smileysound.com{" "}
        </a>{" "}
        if you want to talk bespoke.{" "}
      </p>
    </div>
  )
}

import React from "react"
import thumbnail from "../../static/thumbnailbg.png"
const Thumbnail = props => {
  return (
    <div className="thumbnail">
      <img src={thumbnail} alt="thumbnail" />
      <p>{props.caption}</p>
    </div>
  )
}
export default Thumbnail
